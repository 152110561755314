import React from "react"
import { PrimaryButton, SecondaryButton } from "../Button"
import Ricardo from "../../images/Ricardo.png"
import Niya from "../../images/Niya.png"
import BuildTeam from "../../images/BuildTeam_mobile.png"
import WhereWeAre from "../../images/WhereWeAre.png"
import StoryBlock from "./StoryBlock"

const OurStoryMobile = ({ data }) => {
  const getOurStory = (number) =>
    data.find((ourStory) => ourStory.number === number)
  return (
    <div className="bg-primary-off-white text-black py-16 px-4 flex flex-col lg:hidden">
      <div className="text-3xl font-semibold text-left">
        Here is how our story goes
      </div>
      <StoryBlock data={getOurStory(1)}></StoryBlock>
      <img className="w-full h-auto mt-6" src={Ricardo} alt="Ricardo" />
      <StoryBlock data={getOurStory(2)}></StoryBlock>
      <StoryBlock data={getOurStory(3)}></StoryBlock>
      <img className="w-full h-auto mt-6" src={Niya} alt="Niya" />
      <StoryBlock data={getOurStory(4)}></StoryBlock>
      <StoryBlock data={getOurStory(5)}></StoryBlock>
      <img className="w-full h-auto mt-6" src={BuildTeam} alt="BuildTeam" />
      <div className="text-2xl font-semibold text-center mt-6">
        Where we are now
      </div>
      <img className="w-full h-auto mt-6" src={WhereWeAre} alt="WhereWeAre" />
      <div className="text-lg text-center mt-6 ">
        If you have any interest in learning more about our vision and roadmaps
        for Baseline, we would always love to chat.
      </div>
      <div className="flex flex-col items-center mt-6">
        <a href="/contact-us/">
          <PrimaryButton>Send us an email</PrimaryButton>
        </a>
        <div className="text-lg text-center mt-4 mb-4">Or</div>
        <a
          href="http://calendly.com/baseline-demo"
          target="_blank"
          rel="noreferrer"
        >
          <SecondaryButton>Schedule a demo</SecondaryButton>
        </a>
      </div>
    </div>
  )
}

export default OurStoryMobile
