import React from "react"
import { PrimaryButton, SecondaryButton } from "../Button"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "../../images/AboutUsBackground.svg"
import StoryBlock from "./StoryBlock"

const OurStory = ({ data }) => {
  const getOurStory = (number) =>
    data.find((ourStory) => ourStory.number === number)

  return (
    <div className="hidden lg:block bg-primary-off-white text-black py-16">
      <div className="justify-center text-center">
        <div className="text-3xl font-bold mb-12 text-left px-40">
          Here is how our story goes
        </div>
        <div
          className="bg-no-repeat bg-cover px-40"
          style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
          <div className="flex flex-row items-center">
            <div className="w-2/5 mr-12">
              <StaticImage
                className="w-full h-auto "
                src="../../images/Ricardo.png"
                alt="Ricardo"
                placeholder="blurred"
              />
            </div>
            <div className="w-3/5">
              <StoryBlock data={getOurStory(1)}></StoryBlock>
            </div>
          </div>
          <div className="flex flex-row items-center mt-12">
            <div className="flex flex-col w-11/12">
              <StoryBlock data={getOurStory(2)}></StoryBlock>
              <StoryBlock data={getOurStory(3)}></StoryBlock>
              <StoryBlock data={getOurStory(4)}></StoryBlock>
            </div>
            <div className="ml-12">
              <StaticImage
                className="w-full h-auto"
                src="../../images/Niya.png"
                alt="Niya"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="flex flex-row items-start mt-12">
            <div className="flex flex-col w-1/3 mr-12">
              <div>
                <StaticImage
                  className="w-full h-auto mt-6"
                  src="../../images/Thinh.png"
                  alt="Thinh"
                  placeholder="blurred"
                />
              </div>
              <div>
                <StaticImage
                  className="w-full h-auto mt-6"
                  src="../../images/Lily.png"
                  alt="Lily"
                  placeholder="blurred"
                />
              </div>
              <div>
                <StaticImage
                  className="w-full h-auto mt-6"
                  src="../../images/Quang.png"
                  alt="Quang"
                  placeholder="blurred"
                />
              </div>
              <div>
                <StaticImage
                  className="w-full h-auto mt-6"
                  src="../../images/Phong.png"
                  alt="Phong"
                  placeholder="blurred"
                />
              </div>
            </div>
            <div className="flex flex-col w-2/3 mt-12">
              <StoryBlock data={getOurStory(5)}></StoryBlock>
              <div className=" mt-12">
                <StaticImage
                  className="w-full h-auto"
                  src="../../images/BuildTeam.png"
                  alt="build-team"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
          <div className="px-40">
            <StaticImage
              src="../../images/WhereWeAre.png"
              alt="where we are"
              placeholder="blurred"
            />
            <div className="text-lg text-center mt-12 ">
              If you have any interest in learning more about our vision and
              roadmaps for Baseline, we would always love to chat.
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-6">
        <a href="/contact-us/">
          <PrimaryButton>Send us an email</PrimaryButton>
        </a>
        <div className="text-lg text-center mt-4 mb-4">Or</div>
        <a
          href="http://calendly.com/baseline-demo"
          target="_blank"
          rel="noreferrer"
        >
          <SecondaryButton>Schedule a demo</SecondaryButton>
        </a>
      </div>
    </div>
  )
}

export default OurStory
