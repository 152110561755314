import React from "react"

const StoryBlock = ({ data }) => {
  return (
    <div className="items-center rounded-xl bg-white py-6 px-8 relative my-5">
      <div className="w-10 h-10 rounded-full bg-red text-white absolute -top-2 -left-2 text-xl flex items-center justify-center">
        {data.number}
      </div>
      <div className="text-base">{data.description}</div>
    </div>
  )
}

export default StoryBlock
