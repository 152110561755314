import React from "react";
import OurStory from "../components/aboutUs/OurStory";
import OurStoryMobile from "../components/aboutUs/OurStoryMobile";
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";

export default function AboutUs({ data }) {
  const ourStoryData = data.allOurStoryJson.nodes;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Baseline</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>

      <Layout>
        <OurStory data={ourStoryData} />
        <OurStoryMobile data={ourStoryData} />
      </Layout>
    </>
  );
}
export const query = graphql`
  query OurStoryQuery {
    allOurStoryJson {
      nodes {
        id
        number
        description
      }
    }
  }
`;
